<template>
  <div>
    <table-page dataName="topics"
                ref="table"
                @on-success="fetchSuccess"
                :params="{activity_id:$route.query.id}"
                :request="fetchAppointTopics">
      <template slot="button">
        <el-button type="primary"
                   @click="$router.push({name:'ApponitmentSetTopic',query:{activity_id:$route.query.id}})">添加题目</el-button>
      </template>

      <el-table-column prop="id"
                       show-overflow-tooltip
                       label="ID">
      </el-table-column>
      <el-table-column prop="topic_name"
                       show-overflow-tooltip
                       label="题目">
      </el-table-column>
      <el-table-column prop="sort"
                       show-overflow-tooltip
                       label="排序">
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button type="text"
                     @click="$router.push({name:'ApponitmentSetTopic',query:{activity_id:$route.query.id,id:scope.row.id}})">编辑</el-button>
          <el-button type="text"
                     @click="delTopic(scope.row.id)">删除</el-button>
        </template>

      </el-table-column>
    </table-page>
  </div>

</template>

<script>
import TablePage from '@/components/TablePage'
import { fetchAppointTopics, delAppointTopic } from '@/api/market'
export default {
  name: 'Topics',
  data () {
    return {
      fetchAppointTopics,
      dialogVisible: false,
      paramsObj: {
      },
      exportUrl: ''
    }
  },
  components: {
    TablePage
  },
  methods: {
    onSearch (e) {
      this.searchParams = e
      this.$nextTick(() => {
        this.$refs.table.fetch()
      })
    },
    showTable (id) {
      this.dialogVisible = true
      this.paramsObj = {
        activity_id: id
      }
      this.$nextTick(() => {
        this.$refs.table.fetch()
      })
    },
    fetchSuccess (e) {
      this.exportUrl = e.export_url
    },
    exportData () {
      window.open(this.exportUrl, '_blank')
    },
    delTopic (id) {
      this.$confirm('确认删除', {
        type: 'warning'
      }).then(async () => {
        const res = await delAppointTopic({
          activity_id: this.$route.query.id,
          id: id
        })
        if (res.meta.code === 0) {
          this.$notify({
            title: '成功',
            message: '删除成功',
            type: 'success'
          })
          this.$refs.table.fetch()
        }
      })
    }
  },
  mounted () {

  }
}
</script>

<style lang="less" scoped>
</style>
